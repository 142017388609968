
import { Component, Vue } from 'vue-property-decorator';
import layout from '@/layout/index.vue';
import { UserModule } from '@/store/modules/user';
import { removeWatermark } from '@/utils/watermark';
import { removeCode, removeSkinType } from '@/utils/cookies';
import { ApiGetUserinfo, ApiLogout } from '@/api/login/index'; // 导入接口
@Component({ components: { layout } })
export default class PageNoAccess extends Vue {
  dialogFlag = false;
  get userInfo() {
    return UserModule.useData ?? ({} as any);
  }

  public async done() {
    const {
      data: { msg }
    } = await ApiLogout();
    if (msg === '操作成功') {
      removeCode();
      removeWatermark();
      removeSkinType();
      UserModule.deltoken();
      this.$router.push('/login');
    }
  }

  async created() {
    if (UserModule.token && !UserModule.useData?.id) {
      const {
        data: { data }
      } = await ApiGetUserinfo();

      UserModule.setUserData(data);
    }
  }
}
