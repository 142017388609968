
import { Component, Vue } from 'vue-property-decorator';
import { removeWatermark } from '@/utils/watermark';
declare const window: any;
@Component
export default class PageLogin extends Vue {
  seen = false;
  QRLoginObj = '' as any; // 是否生成了二维码
  client_id = `${process.env.VUE_APP_CLIENT_ID}`; // client_id
  redirect_uri = `${process.env.REDIRECT_URL}`; // 重定向地址
  fs_url = '';

  handleFsLogin() {
    if (this.QRLoginObj !== '') return false; // 避免重複點擊
    this.fs_url =
      'https://passport.feishu.cn/suite/passport/oauth/authorize?client_id=' +
      this.client_id +
      '&redirect_uri=' +
      this.redirect_uri +
      '&response_type=code';
    this.QRLoginObj = window.QRLogin({
      id: 'login_container',
      goto: this.fs_url,
      width: '660',
      height: '660',
      style: 'width:260px;height:260px;border:none' // 可选的，二维码html标签的style属性
    });
    if (typeof window.addEventListener !== 'undefined') {
      window.addEventListener('message', this.handleMessage, false);
    } else if (typeof window.attachEvent !== 'undefined') {
      window.attachEvent('onmessage', this.handleMessage);
    }
  }

  handleMessage() {
    const origin = window.event.origin;
    if (this.QRLoginObj.matchOrigin(origin)) {
      const loginTmpCode = window.event.data;
      if (loginTmpCode) {
        window.location.href = `${this.fs_url}&tmp_code=${loginTmpCode}`;
      }
    }
  }

  mounted() {
    removeWatermark();
    this.handleFsLogin();
  }

  click() {
    window.location.href = `https://passport.feishu.cn/suite/passport/oauth/authorize?client_id=${this.client_id}&redirect_uri=${this.redirect_uri}&response_type=code`;
  }
}
