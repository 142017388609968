
import { Component, Vue } from 'vue-property-decorator';
@Component({ components: {} })
export default class PageHome extends Vue {
  created() {
    this.$popup.model(
      'info',
      '注册提醒',
      '已向管理员提交申请',
      '成功完成账号注册，系统已通知管理员尽快完成设置，或联系管理员',
      '/noAccess'
    );
  }
}
