
import { Component, Vue } from 'vue-property-decorator';
import { setWaterMark } from '@/utils/watermark';
import { getWatermark } from '@/utils/cookies';
@Component({})
export default class Page404 extends Vue {
  mounted() {
    const watermark = getWatermark()?.replace('"', '').replace('"', '');

    if (watermark) {
      setWaterMark(watermark);
    }
  }
}
